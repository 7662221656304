import {
// getProjectTableColumn,
// getRegionTableColumn,
// getStatusTableColumn,
// getBrandTableColumn,
// getCopyWithContentTableColumn,
// getNameDescriptionTableColumn,
// getBillingTypeTableColumn,
// getOsArch,
// getTimeTableColumn,
} from '@/utils/common/tableColumn'
// import SystemIcon from '@/sections/SystemIcon'
// import { sizestr } from '@/utils/utils'
// import i18n from '@/locales'
// import { PRODUCT_TYPES } from '../../../constant'

export default {
  created () {
    this.columns = [
      {
        field: 'name',
        title: '资源名称',
      },
      {
        field: 'provider',
        title: '厂商',
        slots: {
          default: ({ row }) => {
            const item = this.getDictDatas(this.DICT_TYPE.PROVIDER).find(item => item.value === row.provider)
            return [<span>{item?.label || '-'}</span>]
          },
        },
      },
      // getBrandTableColumn({
      //   title: '厂商',
      //   field: 'provider',
      // }),
      // getTimeTableColumn({
      //   title: '修改时间',
      //   field: 'update_time',
      // }),
    ]
  },
}
