<template>
  <div class="severtemplate-create-server">
    <page-header :title="headerTitle" />
    <a-form :form="form.fc" class="mt-3"  v-bind="formItemLayout">
      <a-form-item label="厂商">
        <a-select v-decorator="decorators.provider" @change="handleProviderChange" placeholder="请选择厂商">
          <a-select-option v-for="item in this.getDictDatas(DICT_TYPE.PROVIDER)" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="类型">
        <a-select v-decorator="decorators.product_type" @change="handleProductTypeChange" placeholder="请选择类型">
          <a-select-option v-for="item in this.getDictDatas(DICT_TYPE.PRODUCT_TYPE)" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="名称">
        <a-checkbox-group v-decorator="decorators.code">
          <a-checkbox
            v-for="(v, index) in productNameOpts"
            :key="index"
            :value="v.value"
            :disabled="v.disabled">
            {{ v.label }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
      <page-footer>
        <template v-slot:right>
          <a-button size="large" type="primary" class="mr-2" @click="submit(false)" style="width: 120px;" :loading="loading">保存</a-button>
          <a-button size="large" style="width: 120px;" @click="goBack">{{$t('compute.text_135')}}</a-button>
        </template>
      </page-footer>
    </a-form>
  </div>
</template>

<script>
import WindowsMixin from '@/mixins/windows'
export default {
  name: 'ProductCreate',
  mixins: [WindowsMixin],
  data () {
    return {
      productNameOpts: [],
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fd: {
          id: '',
        },
      },
      decorators: {
        provider: [
          'provider',
          {
            validateFirst: true,
            rules: [
              { required: true, message: '请选择厂商' },
            ],
          },
        ],
        product_type: [
          'product_type',
          {
            rules: [
              { required: true, message: '请选择产品类型' },
            ],
          },
        ],
        code: [
          'code',
          {
            rules: [
              { required: true, message: '请输入产品编码' },
            ],
          },
        ],
        name: [
          'name',
          {
            rules: [
              { required: true, message: '请输入产品名称' },
            ],
          },
        ],
      },
      formItemLayout: {
        wrapperCol: {
          md: { span: 18 },
          xl: { span: 21 },
          xxl: { span: 22 },
        },
        labelCol: {
          md: { span: 6 },
          xl: { span: 3 },
          xxl: { span: 2 },
        },
      },
    }
  },
  computed: {
    providerParams () {
      return {
        usable: true,
        public_cloud: true,
        // ...this.scopeParams,
      }
    },
    headerTitle () {
      return this.$route.query.id ? '编辑云资源' : '新建云资源'
    },
  },
  created () {
    this.fetchDetail()
  },
  methods: {
    fetchDetail () {
      if (!this.$route.query.id) {
        this.form.fd = {
          id: '',
        }
        return
      }
      new this.$Manager('products', 'v1')
        .get({ id: this.$route.query.id })
        .then(({ data }) => {
          console.log('data=', data, this.$refs.areaSelectRef)
          this.$nextTick(() => {
            this.form.fc.setFieldsValue({
              id: data.id,
              code: data.code,
              name: data.name,
              product_type: data.product_type,
              provider: data.provider,
            })
            this.form.fd.id = data.id
            // this.$refs.areaSelectRef.FC.setFieldsValue({
            //   provider: data.provider,
            // })
          })
        })
        .catch(() => {
          this.$message.error('获取详情失败')
          setTimeout(() => {
            this.goBack()
          }, 1500)
        })
    },
    providerFetchSuccess (list) {
      console.log('list=', list)
    },
    handleProviderChange (val) {
      if (val) {
        const { product_type } = this.form.fc.getFieldsValue()
        if (product_type) {
          this.$nextTick(() => {
            this.handleProductTypeChange(product_type)
          })
        }
      }
    },
    handleProductTypeChange (val) {
      console.log('handleProductTypeChange value=', val)
      if (val) {
        const { provider } = this.form.fc.getFieldsValue()
        if (provider) {
          const params = {
            scope: this.$store.getters.scope,
            provider,
            // product_type: val,
            limit: 1000000,
          }

          this.loadExistProducts(params).then(res => {
            console.log('res=', res)
            if (res.data && res.data.length > 0) {
              const existProductCodes = res.data.map(item => item.code)
              const productNameOpts = this.getDictDatas(val)
              this.productNameOpts = productNameOpts.map(item => {
                if (existProductCodes.includes(item.value)) {
                  return {
                    ...item,
                    disabled: true,
                  }
                } else {
                  return item
                }
              })
            } else {
              this.productNameOpts = this.getDictDatas(val)
            }
          })
        }
      } else {
        this.productNameOpts = []
      }
    },
    async loadExistProducts (params) {
      const response = await new this.$Manager('products', 'v1').list({ params })
      const existProducts = response.data
      // console.log('existProducts=', existProducts)
      return existProducts
    },
    async saveOrUpdate (data) {
      try {
        if (this.form.fd.id) {
          return new this.$Manager('products', 'v1').update({ id: this.form.fd.id, data })
        } else {
          return new this.$Manager('products', 'v1').create({ data })
        }
      } catch (error) {
        throw error
      }
    },
    async submit (enabled) {
      this.loading = true
      try {
        const values = await this.form.fc.validateFields()
        // const providerValues = this.$refs.areaSelectRef.FC.getFieldsValue()
        // console.log('submit values:', values)
        const productNameOpts = this.getDictDatas(values.product_type)
        const selectedProducts = productNameOpts.filter(item => values.code.includes(item.value))
        Object.assign(values, {
          generate_name: selectedProducts.map(item => item.label),
          __count__: values.code.length,
        })
        if (values.code?.length === 1) {
          values.code = values.code[0]
          values.name = values.generate_name[0]
          delete values.generate_name
        }
        console.log('submit values:', values)
        // return
        // Object.assign(values, providerValues)
        if (enabled) {
          Object.assign(values, { enabled: true })
        } else {
          Object.assign(values, { enabled: false })
        }
        const res = await this.saveOrUpdate(values)
        console.log('res=', res)
        if (res.data) {
          const msg =
            this.form.fd.id
              ? '编辑成功'
              : '新增成功'
          this.$message.success(msg)
          this.goBack()
        }

        this.loading = false
      } catch (error) {
        this.loading = false
        throw error
      }
    },
    goBack () {
      this.$router.push('/product')
    },
  },
}
</script>

<style lang="less" scoped>

</style>
