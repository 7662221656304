<template>
  <page-list
    :list="list"
    :defaultSearchKey="'product_name'"
    :columns="columns"
    :group-actions="groupActions"
    :single-actions="singleActions"
    :showSearchbox="showSearchbox"
    :show-group-actions="showGroupActions"
    :show-single-actions="showSingleActions"
    :export-data-options="exportDataOptions" />
</template>

<script>
// import expectStatus from '@/constants/expectStatus'
// import { getBrandItems } from '@/utils/common/tableFilter'
import WindowsMixin from '@/mixins/windows'
import ListMixin from '@/mixins/list'
import GlobalSearchMixin from '@/mixins/globalSearch'
import SingleActionsMixin from '../mixins/singleActions'
import ColumnsMixin from '../mixins/columns'

export default {
  name: 'ProductPriceList',
  mixins: [WindowsMixin, ListMixin, GlobalSearchMixin, ColumnsMixin, SingleActionsMixin],
  props: {
    id: String,
    getParams: {
      type: Object,
      default: () => ({}),
    },
    cloudEnv: String,
    showGroupActions: {
      type: Boolean,
      default: () => (true),
    },
    showSingleActions: {
      type: Boolean,
      default: () => (true),
    },
    cloudEnvOptions: {
      type: Array,
    },
  },
  data () {
    return {
      productTypeOpts: [],
      chargeTypeOpts: [],
      list: this.$list.createList(this, {
        id: this.id,
        resource: 'product_prices',
        apiVersion: 'v1',
        getParams: this.getParam,
        // steadyStatus: Object.values(expectStatus.servertemplate).flat(),
        filterOptions: {
          // id: {
          //   label: this.$t('table.title.id'),
          // },
          product_name: {
            label: '产品名称',
          },
          // status: getStatusFilter('servertemplate'),
          // provider: getBrandFilter({
          //   label: '厂商',
          // }),
          provider: {
            label: '厂商',
            dropdown: true,
            items: this.getDictDatas(this.DICT_TYPE.PROVIDER).map(item => ({
              label: item.label,
              key: item.value,
            })),
          },
          product_type: {
            label: '产品类型',
            dropdown: true,
            items: this.getDictDatas(this.DICT_TYPE.PRODUCT_TYPE).map(item => ({
              label: item.label,
              key: item.value,
            })),
          },
          charge_type: {
            label: '计费方式',
            dropdown: true,
            items: this.getDictDatas(this.DICT_TYPE.CHARGE_TYPE).map(item => ({
              label: item.label,
              key: item.value,
            })),
          },
          // created_at: getCreatedAtFilter(),
        },
        hiddenColumns: ['created_at'],
        responseData: this.responseData,
      }),
      groupActions: [
        // {
        //   label: this.$t('compute.perform_create'),
        //   permission: 'product_prices_create',
        //   action: () => {
        //     this.$router.push({
        //       path: '/product-price/createOrUpdate',
        //     })
        //   },
        //   meta: () => {
        //     return {
        //       buttonType: 'primary',
        //     }
        //   },
        // },
        // {
        //   label: this.$t('compute.perform_delete'),
        //   permission: 'servertemplates_delete',
        //   action: () => {
        //     this.createDialog('DeleteResDialog', {
        //       vm: this,
        //       data: this.list.selectedItems,
        //       columns: this.columns,
        //       title: this.$t('compute.perform_delete'),
        //       name: this.$t('dictionary.servertemplate'),
        //       onManager: this.onManager,
        //     })
        //   },
        //   meta: () => this.$getDeleteResult(this.list.selectedItems),
        // },
      ],
    }
  },
  computed: {
    _cloudEnv () {
      if (this.cloudEnvOptions && this.cloudEnvOptions.length > 0) {
        const idc = this.cloudEnvOptions.find(item => item.key === 'idc')
        if (idc && idc.key) {
          return idc.key
        }
        const item = this.cloudEnvOptions.find(item => item.key) || {}
        return item.key
      }
      return ''
    },
    exportDataOptions () {
      return {
        downloadType: 'local',
        title: '云资源定价',
        items: [
          { field: 'id', title: 'ID' },
          ...this.columns,
        ],
      }
    },
  },
  watch: {
    cloudEnv (val) {
      this.$nextTick(() => {
        this.list.fetchData(0)
      })
    },
  },
  created () {
    this.list.fetchData()
    this.initSidePageTab('product-price-detail')
  },
  methods: {
    getParam () {
      const ret = {
        details: true,
        ...this.getParams,
      }
      if (this.cloudEnv) ret.cloud_env = this.cloudEnv
      return ret
    },
    handleOpenSidepage (row, tab) {
      this.sidePageTriggerHandle(this, 'ProductPriceSidePage', {
        id: row.id,
        resource: 'product_prices',
        apiVersion: 'v1',
        getParams: this.getParam,
      }, {
        list: this.list,
        tab,
      })
    },
  },
}
</script>
