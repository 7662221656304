// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
// import i18n from '@/locales'
export default {
  created () {
    this.singleActions = [
      {
        label: '配置可用地域',
        // permission: 'resource_regions_update',
        action: row => {
          // 配置可用地域
          this.createDialog('AvailableAreaConfigUpdateDialog', {
            refresh: this.refresh,
            row: row,
          })
        },
      },
      {
        label: '可用区映射',
        // permission: 'resource_regions_update',
        action: row => {
          // 可用区映射
          this.createDialog('AreaMappingTable', {
            refresh: this.refresh,
            row: row,
          })
        },
      },
    ]
  },
}
