// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
// import i18n from '@/locales'
export default {
  created () {
    this.singleActions = [
      {
        label: '配置可用规格',
        // permission: 'resource_specifications_update',
        action: async row => {
          let queryResource = '' // 查询条件地址
          let resource = '' // 列表地址
          let unSupported = false // 是否不支持
          const product_code = row.code
          switch (product_code) {
            case 'ecs':
              queryResource = '/v2/serverskus/ecs-sku-query'
              resource = '/v2/serverskus/ecs-sku'
              break
            case 'ebs':
            case 'disk':
              queryResource = '/v3/ebs_sku_on_area_maps/ebs-sku-query'
              resource = '/v3/ebs_sku_on_area_maps/ebs-sku'
              break
            case 'eip':
              queryResource = '/v3/eip_sku_on_area_maps/eip-sku-query'
              resource = '/v3/eip_sku_on_area_maps/eip-sku'
              break
            case 'slb':
              queryResource = '/v3/slb_sku_on_area_maps/slb-sku-query'
              resource = '/v3/slb_sku_on_area_maps/slb-sku'
              break
            case 'nat_gateway':
              // queryResource = '/v2/nat_sku_on_area_maps/nat-gateway-sku-query'
              // resource = '/v2/nat_sku_on_area_maps/nat-gateway-sku'
              queryResource = '/v2/nat_skus/nat-gateway-sku-query'
              resource = '/v2/nat_skus/nat-gateway-sku'
              break
            default:
              unSupported = true
              this.$message.warning('暂无配置可用规格')
              break
          }
          if (unSupported) return

          const { data: queryData } = await this.$http.get(`${queryResource}?provider=${row.provider}`)
          // 配置可用规格
          this.createDialog('AvailableSpecificationConfigUpdateDialog', {
            refresh: this.refresh,
            row: row,
            resource: resource,
            queryData: queryData || {},
          })
        },
      },
    ]
  },
}
