<template>
  <page-list
    :list="list"
    :columns="columns"
    :group-actions="groupActions"
    :single-actions="singleActions"
    :showSearchbox="showSearchbox"
    placeholder="选择资源属性进行过滤"
    :show-group-actions="showGroupActions"
    :show-single-actions="showSingleActions"
    :export-data-options="exportDataOptions" />
</template>

<script>
// import { getNameFilter } from '@/utils/common/tableFilter'
import WindowsMixin from '@/mixins/windows'
import ListMixin from '@/mixins/list'
import GlobalSearchMixin from '@/mixins/globalSearch'
import SingleActionsMixin from '../mixins/singleActions'
import ColumnsMixin from '../mixins/columns'
import { hasPermission } from '@/utils/auth'

export default {
  name: 'ProductList',
  mixins: [WindowsMixin, ListMixin, GlobalSearchMixin, ColumnsMixin, SingleActionsMixin],
  props: {
    id: String,
    getParams: {
      type: Object,
      default: () => ({}),
    },
    cloudEnv: String,
    showGroupActions: {
      type: Boolean,
      default: () => (true),
    },
    showSingleActions: {
      type: Boolean,
      default: () => (true),
    },
    cloudEnvOptions: {
      type: Array,
    },
  },
  data () {
    return {
      productTypeOpts: [],
      chargeTypeOpts: [],
      list: this.$list.createList(this, {
        id: this.id,
        resource: 'products',
        apiVersion: 'v2',
        getParams: this.getParam,
        // steadyStatus: Object.values(expectStatus.servertemplate).flat(),
        filterOptions: {
          // id: {
          //   label: this.$t('table.title.id'),
          // },
          code: {
            label: '产品编码',
          },
          // name: getNameFilter(),
          provider: {
            label: '厂商',
            dropdown: true,
            items: this.getDictDatas(this.DICT_TYPE.PROVIDER).map(item => ({
              label: item.label,
              key: item.value,
            })),
          },
          product_type: {
            label: '产品类型',
            dropdown: true,
            items: this.getDictDatas(this.DICT_TYPE.PRODUCT_TYPE).map(item => ({
              label: item.label,
              key: item.value,
            })),
          },
          // created_at: getCreatedAtFilter(),
        },
        hiddenColumns: ['created_at'],
        responseData: this.responseData,
      }),
      groupActions: [
        {
          label: this.$t('compute.perform_create'),
          permission: 'products_create',
          action: () => {
            this.$router.push({
              path: '/product/createOrUpdate',
            })
          },
          meta: () => {
            return {
              buttonType: 'primary',
              // validate: !!this._cloudEnv,
            }
          },
        },
        // {
        //   label: this.$t('compute.perform_delete'),
        //   permission: 'servertemplates_delete',
        //   action: () => {
        //     this.createDialog('DeleteResDialog', {
        //       vm: this,
        //       data: this.list.selectedItems,
        //       columns: this.columns,
        //       title: this.$t('compute.perform_delete'),
        //       name: this.$t('dictionary.servertemplate'),
        //       onManager: this.onManager,
        //     })
        //   },
        //   meta: () => this.$getDeleteResult(this.list.selectedItems),
        // },
      ],
    }
  },
  computed: {
    isPermission () {
      return hasPermission({ key: 'area_mappings_list' })
    },
    exportDataOptions () {
      return {
        downloadType: 'local',
        title: '云资源管理',
        items: [
          { field: 'id', title: 'ID' },
          ...this.columns,
        ],
      }
    },
  },
  watch: {
    cloudEnv (val) {
      this.$nextTick(() => {
        this.list.fetchData(0)
      })
    },
  },
  created () {
    this.list.fetchData()
    this.initSidePageTab('product-detail')
  },
  methods: {
    getParam () {
      const ret = {
        details: true,
        ...this.getParams,
      }
      if (this.cloudEnv) ret.cloud_env = this.cloudEnv
      return ret
    },
    handleOpenSidepage (row, tab) {
      this.sidePageTriggerHandle(this, 'ProductSidePage', {
        id: row.id,
        resource: 'products',
        apiVersion: 'v1',
        getParams: this.getParam,
      }, {
        list: this.list,
        tab,
      })
    },
    // TODO 产品区域映射表
    handleRegionMapTable () {
      this.createDialog('AreaMappingTable', {
        refresh: this.list,
      })
    },
  },
}
</script>
